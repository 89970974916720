<template>
	<img class="h-img" :class="hoverClass" :src="srcComputed">
</template>

<script>
	export default {
		name: 'h-img',
		props: ['src','hoverClass'],
		computed: {
			srcComputed() {
				// console.log("this.src: ",this.src);
				if (this.src) {
					if(this.src.startsWith('http')){
						return this.src
					} else {
						console.log(this.webImg);
						return this.webImg + this.src
					}
				} else {
					return ''
				}
				
			}
		}
	}
</script>

<style lang="scss" scoped>
	.h-img {
		&:hover {
			
		}
		// width: 100%;
	}
</style>