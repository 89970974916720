import {
	mapState,
	mapGetters
} from 'vuex'

export default {
	data() {
		return {
			webImg: process.env.VUE_APP_IMG_ROOT, //网络图片前缀
			_lastTime: null, //上次点击时间
			isOffLine: false, //是否断网了
			time: {
				hour: "",
				minitus: "",
				seconds: ""
			},
			nowTime: "",
			week: [
				"星期天",
				"星期一",
				"星期二",
				"星期三",
				"星期四",
				"星期五",
				"星期六"
			],
		};
	},
	computed: {
		...mapState(['erJiMenu_path']),
		...mapGetters(['isMobile'])
	},

	// beforeRouteEnter(to, from, next) {
	// 	// console.log("to: ",to);
	// 	// console.log("window.history: ",window.history);
	// 	next(vm => {
	// 		// console.log("vm.$router: ",vm.$router);
	// 		new WOW({
	// 			boxClass: 'wow',
	// 			animateClass: 'animated',
	// 			offset: 150,
	// 			mobile: true,
	// 			live: true
	// 		}).init();
	// 	})
	//     // react to route changes...
	//     // don't forget to call next()

	// },


	mounted() {
		// new WOW({
		// 	boxClass: 'wow',
		// 	animateClass: 'animated',
		// 	offset: this.isMobile ? 0:150,
		// 	mobile: true,
		// 	live: false
		// }).init();
		// this.dateTime()
	},
	methods: {
		dateTime() {
			this.timeFormate();
			setTimeout(() => {
				this.dateTime();
			}, 1000);
		},
		numberFormate(val) {
			let a = parseFloat(val)
			return a
		},
		timeFormate() {
			const newtime = new Date();
			this.time.hour = this.getIncrease(newtime.getHours(), 2);
			this.time.minitus = this.getIncrease(newtime.getMinutes(), 2);
			this.time.seconds = this.getIncrease(newtime.getSeconds(), 2);
			this.nowTime =
				this.getIncrease(newtime.getFullYear(), 4) +
				"年" +
				this.getIncrease(newtime.getMonth() + 1, 2) +
				"月" +
				this.getIncrease(newtime.getDate(), 2) +
				"日 " +
				this.week[newtime.getDay()];
			return `${this.time.hour}:${this.time.minitus}:${this.time.seconds}`;
		},
		getIncrease(num, digit) {
			var increase = "";
			for (var i = 0; i < digit; i++) {
				increase += "0";
			}
			return (increase + num).slice(-digit);
		},
		handleVisiable(e) {
			let isExist = e.target.visibilityState
			console.log(isExist)
			if (isExist === 'visible') {
				this.mathRdm()
				console.log(new Date().toLocaleString(), `您已进入页面！`)
			} else {
				clearInterval(this.timer)
				console.log(new Date().toLocaleString(), `您已离开页面！`)
			}
			switch (e.target.visibilityState) {
				case 'prerender':
					console.log('网页预渲染，内容不可见')
					break;
				case 'hidden':
					console.log('内容不可见，处理后台、最小化、锁屏状态')
					break;
				case 'visible':
					console.log('处于正常打开')
					break;
			}
		},
		mathRdm() {
			let num = 5000
			this.timer = setInterval(() => {

			}, num)
		},
		/**
		 * 数字字段值转换 规避toFixed 精度丢失问题
		 * @param {输入的数字 string、int 类型均可} n
		 * @param {保留位数 可输入 0} d
		 * @returns
		 */
		toFixedNum(n, d) {
			// 判断是小数
			var isFloat = parseInt(n) < parseFloat(n);

			if (typeof(d) == "undefined") {
				// 获取浮点型数值的小数位数
				d = (n != Math.floor(n)) ? (n.toString()).split('.')[1].length : 0;
			}

			if (isFloat) {
				n = Number(n);
				d = Number(d);
				n = n * Math.pow(10, d);
				n = Math.round(n);
				n = Number(n);
				n = n / Math.pow(10, d);
				n = n.toFixed(d);
				n = Number(n);
			} else {
				n = Number(n);
			}

			return n;
		},
		//数字转换为万单位
		formatNumber(num) {
			num = Number(num);
			if (num == 0) {
				return num + '';
			} else if (num > 1 && num < 10000) {
				return num + '';
			} else {
				return (num / 10000).toFixed(2);
			}
		},
		throttle(fn, gapTime) {
			if (gapTime == null || gapTime == undefined) {
				gapTime = 500 // 默认500毫秒
			}
			let _nowTime = +new Date()
			if (_nowTime - this._lastTime > gapTime || !this._lastTime) {
				fn()
				// this.$message.success('刷新成功')
				this._lastTime = _nowTime
			}
		},
		getYear() {
			let date = new Date() // 如果date为13位不需要乘1000
			let Y = date.getFullYear()
			return Y
		},
		getDate(StringDate) {
			let date = new Date(StringDate) // 如果date为13位不需要乘1000
			let Y = date.getFullYear() + '-'
			let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
			let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
			let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
			let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
			let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
			return Y + M + D
		},
		getDateTime(StringDate) {
			let date = new Date(StringDate) // 如果date为13位不需要乘1000
			let Y = date.getFullYear() + '-'
			let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
			let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' '
			let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
			let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
			let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
			return Y + M + D
		},
		getDateTimes(StringDate) {
			let date = new Date(StringDate) // 如果date为13位不需要乘1000
			let Y = date.getFullYear() + '-'
			let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
			let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' '
			let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
			let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
			let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
			return Y + M + D + h + m
		},
		getDateTime1(StringDate) {
			let date = new Date(StringDate) // 如果date为13位不需要乘1000
			let Y = date.getFullYear() + '-'
			let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
			let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' '
			let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ' :'
			let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ' :'
			let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
			return h + " " + m + " " + s
		},
		getDateTime2(StringDate) {
			let date = new Date(StringDate) // 如果date为13位不需要乘1000
			let Y = date.getFullYear() + '-'
			let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
			let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' '
			let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ' : '
			let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ' : '
			let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
			return Y + M + D + h + m + s
		},
		//控制富文本图片大小
		formatRichText(html) {
			console.log(html, 'llll');
			// let newContent = html.replace(/<img[^>]*>/gi, function(match, capture) {
			// 	match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
			// 	match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
			// 	match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
			// 	return match;
			// });
			// newContent = newContent.replace(/style="[^"]+"/gi, function(match, capture) {
			// 	match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi,
			// 		'max-width:100%;');
			// 	return match;
			// });
			// newContent = newContent.replace(/<br[^>]*\/>/gi, '');
			// newContent = newContent.replace(/\<img/gi,
			// 	'<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
			// return newContent;



			var newContent = html.replace(/<img[^>]*>/gi, function(match, capture) {
				let processed = null;

				if (!match.match(/style=\"(.*)\"/gi)) {
					processed = match.replace(/\<img/g, '<img style="width:100%;height:auto;display:block"');
				} else {
					processed = match.replace(/style=\"(.*)\"/gi,
						'style="width: 100%;height:auto;display:block;margin: 0 auto;"');
				}

				return processed;
			});
			return newContent;
		},
	},
}