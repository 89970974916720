// 菜单
import request from '@/util/request.js'

// 查询一级菜单-二级菜单
export function MenuList(parentId) {
	return request({
		method: 'get',
		url: `/api/menu/list?parentId=${parentId}`
	})
}

// 查询所有菜单
export function MenuListAll() {
	return request({
		method: 'get',
		url: `/api/menu/listAll`
	})
}
