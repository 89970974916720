// 配置
import request from '@/util/request.js'

// 按分组查询配置
export function ConfigConfigGroup(configGroup) {
	return request({
		method: 'get',
		url: `/api/config/configGroup/${configGroup}`
	})
}

// 配置查询
// export function ConfigConfigKey(configKey) {
// 	return request({
// 		method: 'get',
// 		url: `/api/config/configKey/${configKey}`
// 	})
// }

// 获取所有配置
export function ConfigListAll() {
	return request({
		method: 'get',
		url: `/api/config/listAll`
	})
}
