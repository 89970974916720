<template>
	<div ref="app" id="app">
		<template v-if="menuListAll.length">
			<commonHeader></commonHeader>
			<template v-if="isMobile">
				<router-view class="body" :style="{minHeight: isMobile ? 'calc(100vh - 50px)':'calc(100vh - 62px)'}"></router-view>
			</template>
			<template v-else>
				<keep-alive><router-view class="body"></router-view></keep-alive>
			</template>
			<commonFooter></commonFooter>
			<!-- <div class="router" @click="$router.push({name: 'index'})">页面</div> -->
			<div class="back-top" :class="isVisibility ? 'animate__animated animate__zoomIn' : 'animate__animated animate__zoomOut'" :style="[backTopStyle]" @click="backTop"></div>
		</template>
	</div>
</template>
<script>
import commonHeader from '@/components/commonHeader/commonHeader.vue';
import commonFooter from '@/components/commonFooter/commonFooter.vue';
import { mapState } from 'vuex';
import { MenuListAll } from '@/apis/caiDan.js';
export default {
	components: {
		commonHeader,
		commonFooter
	},
	data() {
		return {
			backTopStyle: {},
			isVisibility: false
		};
	},
	computed: {
		...mapState(['menuListAll'])
	},
	watch: {
		$route: {
			handler(val, oldval) {
				// console.log(val, 'val'); //新路由信息
				// console.log(oldval, 'oldval'); //老路由信息
			},
			// 深度观察监听
			deep: true
		}
	},
	created() {
		this.watchWindowSize();
		window.addEventListener('resize', this.watchWindowSize);
		// 初始化菜单
		MenuListAll().then(res => {
			let arr = [
				{
					menuName: '首页',
					path: 'shouYe',
					children: []
				},
				...res.data
			];
			this.$store.commit('changeMenuListAll', arr);
		});

		// 获取网站信息
		this.$store.dispatch('changeConfigListAllAction');

		// 返回顶部设置
		window.onscroll = () => {
			console.log('window.scrollY: ', window.scrollY);

			var timeoutInt; // 要保证最后要运行一次

			// setTimeout(function() {
			if (timeoutInt != undefined) {
				window.clearTimeout(timeoutInt);
			}
			timeoutInt = window.setTimeout(() => {
				getScrollHeight();
				console.log(getDocumentTop() + getWindowHeight());
				// console.log(getScrollHeight());
				// 监听事件内容
				if (getScrollHeight() == getDocumentTop() + getWindowHeight()) {
					//当滚动条到底时,这里是触发内容
					//异步请求数据,局部刷新dom
					// ajax_function(); //调用上面自定义的事件函数。
					console.log('滚动到底部了');
					this.$bus.$emit('onReachBottom', '123');
				}
			});
			// }, 100);

			if (window.scrollY > window.screen.height / 2) {
				this.isVisibility = true;
			} else {
				this.isVisibility = false;
				return;
			}
			if (!this.isMobile) {
				let gap = window.screen.width - 1200 - 80 * 2;
				if (gap > 0) {
					this.backTopStyle = {
						position: 'fixed',
						bottom: '50px',
						zIndex: 9999,
						display: 'block',
						right: (gap / 2 - 100) / 2 + 'px',
						width: '80px',
						height: '80px',
						opacity: 1
					};
				} else {
					this.backTopStyle = {
						position: 'fixed',
						bottom: '50px',
						zIndex: 9999,
						display: 'block',
						right: '12px',
						width: '80px',
						height: '80px',
						opacity: 1
					};
				}
			} else {
				let gap = window.screen.width - 1200 - 40 * 2;
				if (gap > 0) {
					this.backTopStyle = {
						position: 'fixed',
						bottom: '180px',
						zIndex: 9999,
						display: 'block',
						right: (gap / 2 - 40) / 2 + 'px',
						width: '40px',
						height: '40px',
						opacity: 1
					};
				} else {
					this.backTopStyle = {
						position: 'fixed',
						bottom: '180px',
						zIndex: 9999,
						display: 'block',
						right: '12px',
						width: '40px',
						height: '40px',
						opacity: 1
					};
				}
			}

			//（浏览器窗口上边界内容高度）
			function getDocumentTop() {
				var scrollTop = 0,
					bodyScrollTop = 0,
					documentScrollTop = 0;
				if (document.body) {
					bodyScrollTop = document.body.scrollTop;
				}
				if (document.documentElement) {
					documentScrollTop = document.documentElement.scrollTop;
				}
				scrollTop = bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
				console.log('scrollTop:' + scrollTop);
				return scrollTop;
			} //可视窗口高度（屏幕可以看见的高度）

			function getWindowHeight() {
				var windowHeight = 0;
				if (document.compatMode == 'CSS1Compat') {
					windowHeight = document.documentElement.clientHeight;
				} else {
					windowHeight = document.body.clientHeight;
				}
				console.log('windowHeight:' + windowHeight);
				return windowHeight;
			} //滚动条滚动高度（即整个网页的高度）

			function getScrollHeight() {
				var scrollHeight = 0,
					bodyScrollHeight = 0,
					documentScrollHeight = 0;
				if (document.body) {
					bodyScrollHeight = document.body.scrollHeight;
				}
				if (document.documentElement) {
					documentScrollHeight = document.documentElement.scrollHeight;
				}
				scrollHeight = bodyScrollHeight - documentScrollHeight > 0 ? bodyScrollHeight : documentScrollHeight;
				console.log('scrollHeight:' + scrollHeight);
				return scrollHeight;
			}
		};
	},
	mounted() {
		// new WOW({
		// 	boxClass: 'wow',
		// 	animateClass: 'animated',
		// 	offset: 150,
		// 	mobile: true,
		// 	live: true
		// }).init();
	},
	methods: {
		// 返回顶部动画
		backTop() {
			var duration = 500;
			var millisecond = 10;
			var height = ~parseInt(document.documentElement.scrollTop / (duration / millisecond));
			var timer = setInterval(function() {
				if (document.documentElement.scrollTop > 0) {
					window.scrollBy(0, height);
				} else {
					clearInterval(timer);
				}
			}, millisecond);
		},
		watchWindowSize() {
			// 获取窗口的宽度和高度，不包括滚动条
			var w = document.documentElement.clientWidth;
			var h = document.documentElement.clientHeight;
			this.$store.commit('changeScreenWidth', w);
		}
	}
};
</script>
<style lang="scss">
// @import url('@wangeditor/editor/dist/css/style.css');
#app {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	background-color: #fff;

	.pic-black {
		background-color: #000;
	}
}

@media screen and (max-width: 800px) {
	.el-message {
		min-width: auto !important;
	}
}

img {
	object-fit: cover;
}

.textAlignJustify {
	text-align: justify
}

@import './animate.scss';

</style>
<style lang="scss" scoped>
.body {
	flex: 1;
	min-height: calc(100vh - 62px);
}
.router {
	z-index: 99999;
	position: fixed;
	top: 10px;
	right: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	background-color: aqua;
	color: #fff;
}
.back-top {
	display: flex;
	border-radius: 50%;
	background-image: url('assets/013.png');
	background-size: 100% 100%;
	background-color: transparent;
	cursor: pointer;
}
</style>
