<template>
	<div class="common-header" id="common-header">
		<div class="placeholder-box"></div>
		<div class="fixed-box">
			<div class="body-container">
				<div class="logo-box">
					<h-img class="logo" :src="configListAll.website_logo"></h-img>
				</div>
				<div class="navbar-box">
					<div 
					class="item active" 
					:class="item.path == yiJiMenu_path ? 'active':'normal'"
					v-for="(item,index) in menuListAll" 
					:key="item.path" @click="item_click(item,index)">
						<div class="txt">{{item.menuName}}</div>
						<div class="line" v-if="item.path == yiJiMenu_path"></div>
					</div>
				</div>
				<div class="right-block"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import {MenuList} from '@/apis/caiDan.js'
	export default {
		name: 'commonHeader',
		data() {
			return {
				
			}
		}, 
		computed: {
			...mapState(['configListAll', 'menuListAll', 'yiJiMenu_path'])
		},
		created() {
			
		},
		mounted() {
			
		},
		methods: {
			item_click(item,index) {
				if (this.yiJiMenu_path == item.path && this.$route.name == item.path) {
					return
				} else {
					this.$store.commit('changeErJiMenuPath','')
					this.$router.push({name: item.path})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import './commonHeader.scss';
	@import './commonHeaderMedia.scss';
</style>