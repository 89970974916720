import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
	// {
	//   path: '/',
	//   name: 'index',
	//   component: () => import('../views/index.vue')
	// },
	// 首页
	{
		path: '/shouYe',
		name: 'shouYe',
		component: () => import('../views/shouYe/shouYe.vue'),
	},
	// 产品服务 chanPinFuWu
	{
		path: '/chanPinFuWu',
		name: 'chanPinFuWu',
		component: () => import('../views/chanPinFuWu/chanPinFuWu.vue')
	},
	// 思迅法务 siXunFaWu
	{
		path: '/siXunFaWu',
		name: 'siXunFaWu',
		component: () => import('../views/siXunFaWu/siXunFaWu.vue')
	},
	// 人才招聘 rencaizhaoping
	{
		path: '/rencaizhaoping',
		name: 'rencaizhaoping',
		component: () => import('../views/rencaizhaoping/siXunFaWu.vue')
	},
	// 新闻中心 xinWenZhongXin
	{
		path: '/xinWenZhongXin',
		name: 'xinWenZhongXin',
		component: () => import('../views/xinWenZhongXin/xinWenZhongXin.vue')
	},
	// 关于我们 guanYuWoMen
	{
		path: '/guanYuWoMen',
		name: 'guanYuWoMen',
		component: () => import('../views/guanYuWoMen/guanYuWoMen.vue')
	},
	// 新闻详情 xinWenXiangQing
	{
		path: '/xinWenXiangQing/:id',
		name: 'xinWenXiangQing',
		component: () => import('../views/xinWenZhongXin/xinWenXiangQing/xinWenXiangQing.vue'),
		props: true,
		alias: 'yyy'
	},
	// 我们的优势 woMenDeYouShi
	// {
	//   path: '/woMenDeYouShi',
	//   name: 'woMenDeYouShi',
	//   component: () => import('../views/xinWenZhongXin/woMenDeYouShi/woMenDeYouShi.vue')
	// },
]


const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, saveTop) {
		if (saveTop) {
			return saveTop
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},
})

// 前置路由守卫
router.beforeEach((to, from, next) => {
	console.log("to: ", to);
	console.log("from: ", from);
	// console.log("store.state.menuListAll: ",store.state.menuListAll);
	// console.log("store.state.menuListAll.findIndex(val => val.path == to.name): ",store.state.menuListAll.findIndex(val => val.path == to.name));
	if (to.path == '/') {
		store.commit('changeYiJiMenuPath', 'shouYe')
		next('/shouYe')
		return
	} else if (store.state.menuListAll.findIndex(val => val.path == to.name) > -1) {
		store.commit('changeYiJiMenuPath', to.name)
		next()
	} else {
		if (!store.state.menuListAll.length) {
			store.commit('changeYiJiMenuPath', to.name)
			next()
		} else {
			next()
		}
	}
})

export default router
