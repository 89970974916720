import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { Path } from 'three'
import {ConfigListAll} from '@/apis/peiZhi.js'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		screen_width: '',//屏幕宽度
		configListAll: {},//配置信息
		menuListAll: [],//菜单列表
		yiJiMenu_list: [],//公共头部一级菜单列表
		// yiJiMenu_list: localStorage.getItem('yiJiMenu_list') || [],//公共头部一级菜单列表
		yiJiMenu_path: '',//一级菜单当前路径
		// yiJiMenu_path: localStorage.getItem('yiJiMenu_path') || '',//一级菜单当前路径
		erJiMenu_path: ''//二级菜单当前路径
		// erJiMenu_path: localStorage.getItem('erJiMenu_path') || ''//二级菜单当前路径
	},
	getters: {
		getMenuItem: (state) => (path) => {
			console.log("path: ",path);
			for (var i = 0; i < state.menuListAll.length; i++) {
				console.log("state.menuListAll[i].path: ",state.menuListAll[i].path);
				if (state.menuListAll[i].path == path) {
					return state.menuListAll[i]
				} else {
					for (var j = 0; j < state.menuListAll[i].children.length; j++) {
						if (state.menuListAll[i].children[j].path == path) {
							return {...state.menuListAll[i].children[j],parent:state.menuListAll[i]}
						}
					}
				}
			}
			return {}
		},
		isMobile(state) {
			return state.screen_width <= 800 ? true:false
		}
	},
	mutations: {
		changeScreenWidth(state,data) {
			localStorage.setItem('screen_width',data)
			state.screen_width = data
			console.log("changeScreenWidth: ",data);
		},
		changeConfigListAll(state,data) {
			localStorage.setItem('configListAll',data)
			state.configListAll = data
			// 动态设置网站标题和图标
			document.title = data.website_name
			document.querySelector('#favicon').href= `${process.env.VUE_APP_IMG_ROOT}` + data.website_logo
			console.log("changeConfigListAll: ",data);
		},
		changeMenuListAll(state,data) {
			localStorage.setItem('menuListAll',data)
			state.menuListAll = data
			console.log("changeMenuListAll: ",data);
		},
		changeYiJiMenuList(state,data) {
			localStorage.setItem('yiJiMenu_list',data)
			state.yiJiMenu_list = data
			console.log("changeYiJiMenuList: ",data);
		},
		changeYiJiMenuPath(state,data) {
			localStorage.setItem('yiJiMenu_path',data)
			state.yiJiMenu_path = data
			console.log("changeYiJiMenuPath: ",data);
		},
		changeErJiMenuPath(state,data) {
			localStorage.setItem('erJiMenu_path',data)
			state.erJiMenu_path = data
			console.log("changeErJiMenuPath: ",data);
		},
	},
	actions: {
		changeConfigListAllAction({commit}) {
			ConfigListAll().then(res => {
				commit('changeConfigListAll',res.data)
			})
		}
	},
	modules: {
		
	}
})
