<template>
	<div class="common-footer" id="common-footer">
		<div class="common-footer-container">
			<div class="content">
				<h-img class="logo" :src="configListAll.website_logo"></h-img>
				<div class="nav-list">
					<div class="column column1" v-if="item.menuName != '首页'" v-for="(item,index) in menuListAll" :key="index" >
						<div class="first">{{item.menuName}}</div>
						<div 
						class="item" 
						v-for="(childrenitem,childrenindex) in item.children" 
						:key="childrenindex" 
						@click="goPage(item,childrenitem)">
							{{childrenitem.menuName}}
						</div>
					</div>
					<div class="column column4">
						<div class="first">联系我们</div>
						<div class="item">资讯服务热线</div>
						<div class="item phone">{{configListAll.companyQgrx}}</div>
					</div>
				</div>
			</div>
			<div class="bottom-box">
				<div class="txt-1">{{configListAll.copyright}}</div>
				<img class="pic-036" src="@/assets/036.png" alt="">
				<!-- <div class="txt-2">{{configListAll.beian_code.split('|')[0]}}</div> -->
				<el-link class="txt-2" :underline="false" target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch">
				  {{configListAll.beian_code.split('|')[0]}}
				</el-link>
				<div class="txt-3">|</div>
				<el-link class="txt-2" :underline="false" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
				  {{configListAll.beian_code.split('|')[1]}}
				</el-link>
			</div>
		</div>
		<div class="common-footer-container-mt">
			<div class="label">资讯服务热线</div>
			<div class="phone">{{configListAll.companyQgrx}}</div>
			<div class="bottom-box">
				<img class="pic-036" src="@/assets/036.png" alt="">
				<div class="txt-1">{{configListAll.beian_code}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		name: 'commonFooter',
		data() {
			return {
				
			}
		},
		computed: {
			...mapState(['configListAll','menuListAll'])
		},
		methods: {
			goPage(item,childrenitem) {
				// this.$store.commit('changeYiJiMenuPath',item.path)
				console.log("this.$route: ",this.$route);
				if (this.$route.name == item.path) {
					this.$store.commit('changeErJiMenuPath',childrenitem.path)
					
				} else {
					this.$router.push({name: item.path})
					this.$store.commit('changeErJiMenuPath',childrenitem.path)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import './commonFooter.scss';
	@import './commonFooterMedia.scss';
</style>