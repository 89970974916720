import Vue from 'vue'
import * as echarts from "echarts"
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixins'
// import countTo from 'vue-count-to';
// import foot from './components/foot.vue'
import 'lib-flexible/flexible.js'
Vue.prototype.$echarts = echarts
Vue.mixin(mixins)
// Vue.component('countTo',countTo)
// Vue.component('foot',foot)
// 配置element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 全局组件
import hImg from '@/components/h-img/h-img.vue'
import CountTo from 'vue-count-to'
Vue.component('h-img', hImg)
Vue.component('CountTo', CountTo)

// import "@/assets/text/text.css";
Vue.use(ElementUI);
Vue.config.productionTip = false


new Vue({
	router,
	store,
	render: h => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this //安装全局事件总线
	}
}).$mount('#app')

